@font-face {
  font-family: 'icomoon';
  src:
    url('https://cdn.icomoon.io/122899/websitev5/icomoon.woff2?thll7i') format('woff2'),
    url('https://cdn.icomoon.io/122899/websitev5/icomoon.ttf?thll7i') format('truetype'),
    url('https://cdn.icomoon.io/122899/websitev5/icomoon.woff?thll7i') format('woff'),
    url('https://cdn.icomoon.io/122899/websitev5/icomoon.svg?thll7i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cIcon-"], [class*=" cIcon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cIcon-ins-humana:before {
  content: "\ea62";
}
.cIcon-ins-sutter-health:before {
  content: "\ea4b";
}
.cIcon-ins-anthem:before {
  content: "\ea45";
}
.cIcon-ins-tricare:before {
  content: "\ea3e";
}
.cIcon-ins-aetna:before {
  content: "\ea37";
}
.cIcon-ins-united:before {
  content: "\ea38";
}
.cIcon-ins-cigna:before {
  content: "\ea3a";
}
.cIcon-ins-kaiser-permanente:before {
  content: "\ea3b";
}
.cIcon-ins-medicaid:before {
  content: "\ea3c";
}
.cIcon-ins-medicare:before {
  content: "\ea3d";
}
.cIcon-ins-blue-cross-blue-shield:before {
  content: "\ea39";
}
.cIcon-person-upper-body:before {
  content: "\ea6a";
}
.cIcon-exclamation-circle:before {
  content: "\ea69";
}
.cIcon-person-upper-body-check-small:before {
  content: "\ea67";
}
.cIcon-calendar-add:before {
  content: "\ea66";
}
.cIcon-exclamation-triangle:before {
  content: "\ea65";
}
.cIcon-exclamation-triange:before {
  content: "\ea68";
}
.cIcon-carbon-stethoscope:before {
  content: "\ea64";
}
.cIcon-video-camera:before {
  content: "\ea63";
}
.cIcon-calendar-with-do-not-enter:before {
  content: "\ea61";
}
.cIcon-sore-throat:before {
  content: "\ea5f";
}
.cIcon-ear:before {
  content: "\ea60";
}
.cIcon-worker-with-heart:before {
  content: "\ea5e";
}
.cIcon-arm-in-sling:before {
  content: "\ea5d";
}
.cIcon-exclamation:before {
  content: "\ea5b";
}
.cIcon-shield-and-cross:before {
  content: "\ea54";
}
.cIcon-covid-19-virus:before {
  content: "\ea55";
}
.cIcon-calendar-heart:before {
  content: "\ea56";
}
.cIcon-person-with-heart:before {
  content: "\ea57";
}
.cIcon-cup-of-tea:before {
  content: "\ea58";
}
.cIcon-tummy-ache:before {
  content: "\ea4f";
}
.cIcon-petri-dish:before {
  content: "\ea50";
}
.cIcon-uterus:before {
  content: "\ea51";
}
.cIcon-chest-rash:before {
  content: "\ea52";
}
.cIcon-injured-finger:before {
  content: "\ea53";
}
.cIcon-doc-with-stethoscope:before {
  content: "\ea59";
}
.cIcon-kaiser:before {
  content: "\ea4e";
}
.cIcon-i-labs:before {
  content: "\e99b";
}
.cIcon-flu-vaccine:before {
  content: "\e991";
}
.cIcon-i-imaging:before {
  content: "\e992";
}
.cIcon-i-cardiology:before {
  content: "\e993";
}
.cIcon-i-appt:before {
  content: "\e994";
}
.cIcon-i-prescription:before {
  content: "\e995";
}
.cIcon-i-video:before {
  content: "\e997";
}
.cIcon-i-credit:before {
  content: "\e99a";
}
.cIcon-syringe:before {
  content: "\ea46";
}
.cIcon-defaultReasonIcon:before {
  content: "\e934";
}
.cIcon-headache:before {
  content: "\e917";
}
.cIcon-mental-health-visit:before {
  content: "\ea23";
}
.cIcon-cold-flu-symptoms-instant:before {
  content: "\e97f";
}
.cIcon-sinus-infection-symptoms-instant:before {
  content: "\e980";
}
.cIcon-medication-refill-instant:before {
  content: "\e981";
}
.cIcon-red-eye-conjunctivitis:before {
  content: "\e982";
}
.cIcon-injury:before {
  content: "\e983";
}
.cIcon-poison-oak:before {
  content: "\e984";
}
.cIcon-routine-physical:before {
  content: "\e985";
}
.cIcon-muscle-tendon-strain:before {
  content: "\e986";
}
.cIcon-urinary-tract-infection-uti-symptoms-instant:before {
  content: "\e987";
}
.cIcon-rash:before {
  content: "\e988";
}
.cIcon-sprain:before {
  content: "\e989";
}
.cIcon-diabetes-follow-up-non-insulin:before {
  content: "\e98a";
}
.cIcon-itchy-eyes:before {
  content: "\e98b";
}
.cIcon-reflux-symptoms:before {
  content: "\e98c";
}
.cIcon-smoking-cessation:before {
  content: "\e98d";
}
.cIcon-trouble-sleeping:before {
  content: "\e98e";
}
.cIcon-sty-in-the-eye:before {
  content: "\e98f";
}
.cIcon-weight-loss-counseling:before {
  content: "\e990";
}
.cIcon-heartburn:before {
  content: "\e932";
}
.cIcon-gerd:before {
  content: "\e933";
}
.cIcon-eye-redness:before {
  content: "\e935";
}
.cIcon-eczema:before {
  content: "\e936";
}
.cIcon-dog-bite:before {
  content: "\e937";
}
.cIcon-discharge-from-vagina:before {
  content: "\e938";
}
.cIcon-discharge-from-eye:before {
  content: "\e939";
}
.cIcon-diabetes-test:before {
  content: "\e93a";
}
.cIcon-diabetes-screen-a1c:before {
  content: "\e93b";
}
.cIcon-diabetes-follow-up:before {
  content: "\e93c";
}
.cIcon-cut:before {
  content: "\e93d";
}
.cIcon-cough:before {
  content: "\e93e";
}
.cIcon-contraception-instant:before {
  content: "\e93f";
}
.cIcon-constipation:before {
  content: "\e940";
}
.cIcon-concussion:before {
  content: "\e941";
}
.cIcon-cold-flu-symptoms:before {
  content: "\e942";
}
.cIcon-cholesterol-test1:before {
  content: "\e943";
}
.cIcon-cholesterol-panel-fasting:before {
  content: "\e944";
}
.cIcon-chf-follow-up:before {
  content: "\e945";
}
.cIcon-bug-bite:before {
  content: "\e946";
}
.cIcon-bronchitis-symptoms:before {
  content: "\e947";
}
.cIcon-back-pain:before {
  content: "\e948";
}
.cIcon-asthma:before {
  content: "\e949";
}
.cIcon-acid-reflux:before {
  content: "\e94a";
}
.cIcon-abdominal-pain:before {
  content: "\e94b";
}
.cIcon-allergy-symptoms-instant:before {
  content: "\e94c";
}
.cIcon-canker-sore:before {
  content: "\e94d";
}
.cIcon-cold-sore:before {
  content: "\e94e";
}
.cIcon-toe-nail-removal:before {
  content: "\e94f";
}
.cIcon-lab-blood-glucose:before {
  content: "\e950";
}
.cIcon-gynecological-exam:before {
  content: "\e951";
}
.cIcon-Eye-Exam:before {
  content: "\e952";
}
.cIcon-lab-flu:before {
  content: "\e953";
}
.cIcon-device-post-op-shoe:before {
  content: "\e954";
}
.cIcon-burn-care:before {
  content: "\e955";
}
.cIcon-lab-rapid-hiv:before {
  content: "\e956";
}
.cIcon-device-velcro-brace:before {
  content: "\e957";
}
.cIcon-uc-visit:before {
  content: "\e958";
}
.cIcon-lab-urinalysis:before {
  content: "\e959";
}
.cIcon-incision-drainage:before {
  content: "\e95a";
}
.cIcon-foreign-body-removal:before {
  content: "\e95b";
}
.cIcon-med-intramuscular:before {
  content: "\e95c";
}
.cIcon-laceration-repair-dermabond:before {
  content: "\e95d";
}
.cIcon-lab-mononucleosis:before {
  content: "\e95e";
}
.cIcon-Respiratory-Treatment:before {
  content: "\e95f";
}
.cIcon-med-intravenous:before {
  content: "\e960";
}
.cIcon-aspiration:before {
  content: "\e961";
}
.cIcon-virtual-visit:before {
  content: "\e962";
}
.cIcon-pc-sick-visit:before {
  content: "\e963";
}
.cIcon-rapid-covid-naat:before {
  content: "\ea3f";
}
.cIcon-covid-expediated-sendout:before {
  content: "\ea40";
}
.cIcon-pc-physical-new:before {
  content: "\e964";
}
.cIcon-cvt:before {
  content: "\e9ab";
}
.cIcon-lab-strep:before {
  content: "\e965";
}
.cIcon-med-tdap-vaccine:before {
  content: "\e966";
}
.cIcon-device-crutches:before {
  content: "\e967";
}
.cIcon-med-oral:before {
  content: "\e968";
}
.cIcon-laceration-repair-staples-sutures:before {
  content: "\e969";
}
.cIcon-device-boot:before {
  content: "\e96a";
}
.cIcon-medical-specialty-knee-copy:before {
  content: "\e96b";
}
.cIcon-ekg:before {
  content: "\e96c";
}
.cIcon-lab-rapid-hep-c:before {
  content: "\e96d";
}
.cIcon-lab-fecal-occult-blood-test:before {
  content: "\e96e";
}
.cIcon-lab-chemistry-panel:before {
  content: "\e96f";
}
.cIcon-device-stirrup:before {
  content: "\e970";
}
.cIcon-device-sling:before {
  content: "\e971";
}
.cIcon-device-cane:before {
  content: "\e972";
}
.cIcon-Nose-Bleed-Treatment:before {
  content: "\e973";
}
.cIcon-device-knee-mobilizer:before {
  content: "\e974";
}
.cIcon-imaging-x-ray:before {
  content: "\e975";
}
.cIcon-ear-wax-removal:before {
  content: "\e976";
}
.cIcon-med-flu-vaccine:before {
  content: "\e977";
}
.cIcon-pc-physical-existing:before {
  content: "\e978";
}
.cIcon-med-td-vaccine:before {
  content: "\e979";
}
.cIcon-subungual-hematoma-drainage:before {
  content: "\e97a";
}
.cIcon-Virtual-Instant-Visit:before {
  content: "\e97b";
}
.cIcon-reduction:before {
  content: "\e97c";
}
.cIcon-rapid-hepatitis-c-screening:before {
  content: "\ea41";
}
.cIcon-flight:before {
  content: "\ea42";
}
.cIcon-lab-testing2:before {
  content: "\ea43";
}
.cIcon-general-surgery:before {
  content: "\ea44";
}
.cIcon-forbes:before {
  content: "\ea31";
}
.cIcon-oaklandas:before {
  content: "\ea32";
}
.cIcon-los-angeles-times:before {
  content: "\ea20";
}
.cIcon-nbc-news:before {
  content: "\ea21";
}
.cIcon-wsj:before {
  content: "\ea22";
}
.cIcon-white-full-logo-carbon:before {
  content: "\e9cd";
}
.cIcon-northbay-logo:before {
  content: "\ea2b";
}
.cIcon-covidReadyLogo:before {
  content: "\e9c7";
}
.cIcon-syringe-and-vial:before {
  content: "\ea2e";
}
.cIcon-clipboard:before {
  content: "\ea2f";
}
.cIcon-microscope:before {
  content: "\ea30";
}
.cIcon-map-pin:before {
  content: "\ea2c";
}
.cIcon-plane:before {
  content: "\ea2a";
}
.cIcon-virus-molecule:before {
  content: "\ea27";
}
.cIcon-happy-phone-in-hand:before {
  content: "\ea28";
}
.cIcon-stethoscope-2:before {
  content: "\ea29";
}
.cIcon-emergency-healthcare:before {
  content: "\ea25";
}
.cIcon-shield-cross:before {
  content: "\ea26";
}
.cIcon-test:before {
  content: "\ea1b";
}
.cIcon-map-marker-linear:before {
  content: "\ea18";
}
.cIcon-shoe:before {
  content: "\ea1e";
}
.cIcon-usd:before {
  content: "\ea1a";
}
.cIcon-clock:before {
  content: "\ea1c";
}
.cIcon-walk:before {
  content: "\ea19";
}
.cIcon-profile-picture:before {
  content: "\ea13";
}
.cIcon-child:before {
  content: "\ea0f";
}
.cIcon-nutrition:before {
  content: "\ea10";
}
.cIcon-exercise:before {
  content: "\ea0a";
}
.cIcon-spine:before {
  content: "\ea0b";
}
.cIcon-stethoscope:before {
  content: "\ea0d";
}
.cIcon-dots3:before {
  content: "\ea0e";
}
.cIcon-globe:before {
  content: "\ea09";
}
.cIcon-people:before {
  content: "\ea08";
}
.cIcon-warning:before {
  content: "\ea07";
}
.cIcon-sixty-five-plus:before {
  content: "\ea03";
}
.cIcon-person-mask-2:before {
  content: "\ea04";
}
.cIcon-person-mask-1:before {
  content: "\ea05";
}
.cIcon-badge:before {
  content: "\e9fd";
}
.cIcon-signed-doc:before {
  content: "\e9fe";
}
.cIcon-receipt:before {
  content: "\e9ff";
}
.cIcon-id-card:before {
  content: "\ea00";
}
.cIcon-small-flu:before {
  content: "\e9fc";
}
.cIcon-calendar-time:before {
  content: "\e9fb";
}
.cIcon-info:before {
  content: "\e9fa";
}
.cIcon-edit-pencil:before {
  content: "\e9f7";
}
.cIcon-share:before {
  content: "\e9f5";
}
.cIcon-car:before {
  content: "\e9f3";
}
.cIcon-virtual-care-screen:before {
  content: "\e9f2";
}
.cIcon-gender:before {
  content: "\e9f0";
}
.cIcon-std-test:before {
  content: "\e9ed";
}
.cIcon-gynecological-exam1:before {
  content: "\e9ee";
}
.cIcon-breast:before {
  content: "\e9ef";
}
.cIcon-search:before {
  content: "\e9ec";
}
.cIcon-phone:before {
  content: "\e9ea";
}
.cIcon-my-child:before {
  content: "\e9e8";
}
.cIcon-chat-translate:before {
  content: "\e9e9";
}
.cIcon-allergy2:before {
  content: "\e9e5";
}
.cIcon-person-question:before {
  content: "\e9e6";
}
.cIcon-linkout:before {
  content: "\e9e4";
}
.cIcon-healthcare:before {
  content: "\e9db";
}
.cIcon-plane-outline:before {
  content: "\e9f4";
}
.cIcon-plane2:before {
  content: "\e9e3";
}
.cIcon-logistics:before {
  content: "\e9dc";
}
.cIcon-transportation:before {
  content: "\e9e2";
}
.cIcon-film:before {
  content: "\e9dd";
}
.cIcon-schools:before {
  content: "\e9de";
}
.cIcon-sports:before {
  content: "\e9df";
}
.cIcon-manufacturing:before {
  content: "\e9e0";
}
.cIcon-retail:before {
  content: "\e9e1";
}
.cIcon-conversation-chat-bubble:before {
  content: "\e9d8";
}
.cIcon-monitor-programming-book:before {
  content: "\e9d9";
}
.cIcon-monitor-plus:before {
  content: "\e9da";
}
.cIcon-play2:before {
  content: "\e9d6";
}
.cIcon-play:before {
  content: "\e9d7";
}
.cIcon-chat-heart:before {
  content: "\e9d5";
}
.cIcon-logo:before {
  content: "\e916";
}
.cIcon-mental-health:before {
  content: "\e9cb";
}
.cIcon-uc-band:before {
  content: "\e9cc";
}
.cIcon-blood-glucose:before {
  content: "\e9ca";
}
.cIcon-social-distance:before {
  content: "\e9c9";
}
.cIcon-inf:before {
  content: "\e9c6";
}
.cIcon-list:before {
  content: "\ea2d";
}
.cIcon-hamb:before {
  content: "\e9aa";
}
.cIcon-dots:before {
  content: "\e906";
}
.cIcon-dots2:before {
  content: "\e9c3";
}
.cIcon-icon-package:before {
  content: "\e9ae";
}
.cIcon-prescription-delivery:before {
  content: "\e9c1";
}
.cIcon-appointments:before {
  content: "\e9c0";
}
.cIcon-pie-line-graph-desktop:before {
  content: "\e9bd";
}
.cIcon-calendar-cross:before {
  content: "\ea5c";
}
.cIcon-book-appointment:before {
  content: "\e9be";
}
.cIcon-lab-testing:before {
  content: "\e9bf";
}
.cIcon-guard:before {
  content: "\e9bb";
}
.cIcon-video-call:before {
  content: "\e9b8";
}
.cIcon-audio-call:before {
  content: "\e9b9";
}
.cIcon-mail:before {
  content: "\e9ba";
}
.cIcon-ok2:before {
  content: "\e9b7";
}
.cIcon-faq:before {
  content: "\e9b6";
}
.cIcon-home:before {
  content: "\e9b5";
}
.cIcon-icon-stethoscope:before {
  content: "\e9ac";
}
.cIcon-icon-microscope:before {
  content: "\e9af";
}
.cIcon-icon-map:before {
  content: "\e9b0";
}
.cIcon-icon-gov:before {
  content: "\e9b1";
}
.cIcon-icon-wheel:before {
  content: "\e9b4";
}
.cIcon-ins:before {
  content: "\e9a9";
}
.cIcon-establish-non-physical:before {
  content: "\e9a7";
}
.cIcon-hands-cross:before {
  content: "\ea11";
}
.cIcon-elb-pain:before {
  content: "\e9a8";
}
.cIcon-pedi:before {
  content: "\e9a6";
}
.cIcon-passport:before {
  content: "\ea06";
}
.cIcon-hand-phone-cross:before {
  content: "\ea14";
}
.cIcon-virtual-phone:before {
  content: "\e9c8";
}
.cIcon-phone-video:before {
  content: "\ea47";
}
.cIcon-phone-smile:before {
  content: "\ea0c";
}
.cIcon-icon-phone:before {
  content: "\e9ad";
}
.cIcon-phone-type:before {
  content: "\e9a4";
}
.cIcon-phone-heart:before {
  content: "\e9d2";
}
.cIcon-phone-instant:before {
  content: "\e9c5";
}
.cIcon-symptom-checker-phone2:before {
  content: "\e9d0";
}
.cIcon-medical-app:before {
  content: "\e9a1";
}
.cIcon-icon-checklist:before {
  content: "\e9b2";
}
.cIcon-lab-results:before {
  content: "\e9bc";
}
.cIcon-medical-record:before {
  content: "\e9d4";
}
.cIcon-vitals:before {
  content: "\e9f1";
}
.cIcon-playOval:before {
  content: "\e99f";
}
.cIcon-pauseOval:before {
  content: "\e9a0";
}
.cIcon-mouse:before {
  content: "\e99e";
}
.cIcon-quotes:before {
  content: "\e996";
}
.cIcon-icon_nav_menu:before {
  content: "\e902";
}
.cIcon-plus:before {
  content: "\e999";
}
.cIcon-icon_in-clinic:before {
  content: "\e901";
}
.cIcon-minus:before {
  content: "\e998";
}
.cIcon-region:before {
  content: "\e905";
}
.cIcon-icon_video:before {
  content: "\e903";
}
.cIcon-vuc:before {
  content: "\e9a3";
}
.cIcon-check:before {
  content: "\e97e";
}
.cIcon-check-rounded:before {
  content: "\e9f8";
}
.cIcon-illustrated-check:before {
  content: "\ea24";
}
.cIcon-tick:before {
  content: "\e9c2";
}
.cIcon-plus2:before {
  content: "\ea02";
}
.cIcon-pin:before {
  content: "\e9eb";
}
.cIcon-map-marker:before {
  content: "\e9f6";
}
.cIcon-locArrow:before {
  content: "\e924";
}
.cIcon-location:before {
  content: "\e907";
}
.cIcon-time_morning:before {
  content: "\e909";
}
.cIcon-time_midday:before {
  content: "\e90a";
}
.cIcon-time_afternoon:before {
  content: "\e90b";
}
.cIcon-close1:before {
  content: "\e99c";
}
.cIcon-close:before {
  content: "\e904";
}
.cIcon-x:before {
  content: "\ea01";
}
.cIcon-arrowLeft:before {
  content: "\e92a";
}
.cIcon-arrowRight:before {
  content: "\e929";
}
.cIcon-arr2Left:before {
  content: "\e92b";
}
.cIcon-arr2Right:before {
  content: "\e92e";
}
.cIcon-arr2Up:before {
  content: "\e92d";
}
.cIcon-arr2Down:before {
  content: "\e92c";
}
.cIcon-chevron-left:before {
  content: "\ea5a";
}
.cIcon-arrLeft:before {
  content: "\e90d";
}
.cIcon-arrRight:before {
  content: "\e90c";
}
.cIcon-dropdown-arrow-down:before {
  content: "\ea1d";
}
.cIcon-dropdown-arrow-up:before {
  content: "\ea33";
}
.cIcon-icon_arrow_dropdown:before {
  content: "\e900";
}
.cIcon-bigRightArrow:before {
  content: "\e99d";
}
.cIcon-arrow-right:before {
  content: "\e918";
}
.cIcon-arrow-left3:before {
  content: "\ea4d";
}
.cIcon-arrow-right3:before {
  content: "\ea4c";
}
.cIcon-arrow-left:before {
  content: "\e9d3";
}
.cIcon-arrow-right2:before {
  content: "\e919";
}
.cIcon-arrow-45deg:before {
  content: "\ea16";
}
.cIcon-stats:before {
  content: "\ea15";
}
.cIcon-arrow-down:before {
  content: "\e908";
}
.cIcon-arrow-down-thick:before {
  content: "\ea12";
}
.cIcon-search2:before {
  content: "\e90e";
}
.cIcon-icon_chat:before {
  content: "\e911";
}
.cIcon-icon_notif:before {
  content: "\e912";
}
.cIcon-icon_ehr:before {
  content: "\e913";
}
.cIcon-icon_delivery:before {
  content: "\e914";
}
.cIcon-calendar-appt:before {
  content: "\e9f9";
}
.cIcon-calendar:before {
  content: "\e921";
}
.cIcon-calendar-lightning:before {
  content: "\e9e7";
}
.cIcon-icon_calendar:before {
  content: "\e915";
}
.cIcon-annual-physical:before {
  content: "\ea17";
}
.cIcon-down-arrow:before {
  content: "\e97d";
}
.cIcon-icon-arrows:before {
  content: "\e9b3";
}
.cIcon-prescription:before {
  content: "\e91a";
}
.cIcon-chemistry:before {
  content: "\e9a5";
}
.cIcon-drugs-pills:before {
  content: "\e9a2";
}
.cIcon-PrEP:before {
  content: "\ea34";
}
.cIcon-prescription-pill:before {
  content: "\e9ce";
}
.cIcon-medication-refill:before {
  content: "\e9cf";
}
.cIcon-flu-screening:before {
  content: "\e9c4";
}
.cIcon-lab:before {
  content: "\e91b";
}
.cIcon-referrals:before {
  content: "\e91c";
}
.cIcon-uc:before {
  content: "\e926";
}
.cIcon-pc:before {
  content: "\e927";
}
.cIcon-language:before {
  content: "\e931";
}
.cIcon-patients:before {
  content: "\e930";
}
.cIcon-call:before {
  content: "\e92f";
}
.cIcon-ortho:before {
  content: "\e928";
}
.cIcon-quote:before {
  content: "\e923";
}
.cIcon-star:before {
  content: "\e925";
}
.cIcon-payment:before {
  content: "\e922";
}
.cIcon-geoloc:before {
  content: "\e91d";
}
.cIcon-store-googleplay:before {
  content: "\e910";
}
.cIcon-linkedin:before {
  content: "\ea1f";
}
.cIcon-twitter:before {
  content: "\ea35";
}
.cIcon-apple:before {
  content: "\e91e";
}
.cIcon-store-apple:before {
  content: "\e90f";
}
.cIcon-yelp:before {
  content: "\e91f";
}
.cIcon-instagram:before {
  content: "\ea36";
}
.cIcon-facebook:before {
  content: "\ea48";
}
.cIcon-google:before {
  content: "\e920";
}
.cIcon-arrow-right21:before {
  content: "\e9d1";
}
.cIcon-linkedin1:before {
  content: "\ea49";
}
.cIcon-twitter1:before {
  content: "\ea4a";
}
